// upload images
function propertyUploadImages() {
	if(document.querySelector('.property-form')) {
        // upload image when change input
        $('[data-change="upload-images"]').change(function() {
            var $this = $(this)
            var files = $this[0].files
            // max upload
            var max_upload = 30
            // count uploaded
            var count_uploaded = $('input[name="uploads[]"]').length ? $('input[name="uploads[]"]').length : 0
            // allowed exts
            var allowed_ext = ['image/jpg', 'image/png', 'image/jpeg']
            // pond template
            var template = $('.upload-image-box').children('.upload-image').clone()
            // holder
            var holder = $('#uploaded-images-list')
            // images which is going to be uploaded.
            var images_arr = []
            var date = new Date()
            var time = date.getTime()
            //
            if(files && (count_uploaded < max_upload)) {
                //
                for(var i = 0; i < files.length; i++) {
                    if(jQuery.inArray(files[i].type, allowed_ext) != -1 ) {
                        count_uploaded += 1
                        if(count_uploaded < max_upload) {
                            // append file to must upload
                            images_arr.push(files[i])
                        }
                    }
                }
                //
                for(var i = 0; i < images_arr.length; i++) {
                    var template = $('.upload-image-box .upload-image').clone()
                    template.addClass('pond-' + i + '-' + time)
                    holder.append(template)
                }
                // upload images
                uploadPropertyImages(holder, images_arr, 0, time)
                // clean input files val
                $this.val("")
            }
        });
        // rotate image
        $('.uploaded-images').on('click', '[data-angle]', function () {
            var self = $(this)
            var image = self.attr('data-image')
            var angle = self.attr('data-angle')
            var imageSection = self.parents('.upload-image').children('.thumbnail').children('img')

            $.ajax({
                type: 'POST',
                url: base_url + "/ajax/property/image/rotate",
                data: {
                    image: image,
                    angle: angle
                },
                async: true,
                beforeSend: function () {
                    imageSection.attr({
                        'src':  base_url + '/images/loader.svg'
                    })
                },
                success: function (data) {
                    setTimeout(function () {
                        imageSection.attr('src', base_url + '/uploads/properties/' + data.image + '?' + new Date().getTime())
                    }, 2000)
                }
            });
        })
        // delete image
        $('.uploaded-images').on('click', '.delete-image', function () {
            $(this).parents('.upload-image').remove()
        });
        // sortable images
        $(".uploaded-images").sortable();
        // upload images
        function uploadPropertyImages(holder, files, index, time) {
            var formData = new FormData()
            formData.append('image', files[index])
            $.ajax({
                type: 'POST',
                url: base_url + "/ajax/property/image/upload",
                data: formData,
                async: true,
                mimeTypes: 'multipart/form-data',
                async: true,
                contentType: false,
                processData: false,
                success: function(data) {
                    // thumbnail
                    holder.children('.pond-' + index + '-' + time).children('.thumbnail').children('img').attr('src', base_url + '/uploads/properties/' + data.image)
                    // rotate left
                    holder.children('.pond-' + index + '-' + time).find('a[data-angle="-90"]').attr({'data-image' : data.image, 'class' : '',})
                    // rotate right
                    holder.children('.pond-' + index + '-' + time).find('a[data-angle="90"]').attr({'data-image' : data.image, 'class' : '',})
                    // zoom
                    holder.children('.pond-' + index + '-' + time).find('a[data-fancybox]').attr({'href' : base_url + '/uploads/properties/' + data.image, 'class' : 'mfp-gallery',})
                    // input
                    holder.children('.pond-' + index + '-' + time).append('<input type="hidden" name="uploads[]" value="' + data.image + '">')
                    setTimeout(function() {
                        if(index < files.length - 1) {
                            var next = ((index * 1) + 1)
                            uploadPropertyImages(holder, files, next, time)
                        }
                    }, 100)
                },
                error: function() {
                    setTimeout(function() {
                        if(index < files.length - 1) {
                            var next = ((index * 1) + 1)
                            uploadPropertyImages(holder, files, next, time)
                        }
                    }, 100)
                }
            })
        }
        // zoom image
        $('.uploaded-images').magnificPopup({
            type: 'image',
            delegate: 'a.mfp-gallery',
            fixedContentPos: true,
            fixedBgPos: true,
            overflowY: 'auto',
            closeBtnInside: false,
            preloader: true,
            removalDelay: 0,
            mainClass: 'mfp-fade',
            gallery: {
                enabled: true
            }
        });
    }
}
propertyUploadImages()

// export
export function uploadImages() {
    propertyUploadImages()
}
window._ = require('lodash');

try {
    window.$ = window.jQuery = require('jquery');
    // window.bootstrap = require('./bootstrap.bundle');
    window.Swiper = require('../mobile/swiper.bundle');
} catch (e) {}

// popper js
import '@popperjs/core';
// bootstrap
import 'bootstrap/dist/js/bootstrap';
// nice select
require('jquery-nice-select');
// slick slider
require('slick-slider');
// slick carousel
// require('slick-carousel');
// magnific popup
require('magnific-popup');
// jquery ui
require('jquery-ui');
// lazyload
require('lazyload');
// fslightbox
require("fslightbox");
// jquery ui sortable
require('jquery-ui-sortable-npm');
// csrf token
require('../../common/csrfToken');
// number mask
require('../../common/numbermask');
// property
require('../../common/addEditProperty');
// search
require('../../common/searchProperty');
// uploader
require('../../common/uploader');
// front
require('../../common/front');
// custom
require('./theme');
// import functions
import { showHideSectionByPropertyType, showHideSectionByAdType } from './showHidePropertySections';

// select ad type
function selectAdType() {
	if (document.querySelector('.advanced-search-form')) {
		const formEle = document.querySelector('.advanced-search-form')
		if (formEle.querySelector('select#ad-type')) {
			const adTypeSelect = formEle.querySelector('select#ad-type')
			// get current ad type
			let currentAdType = $('select#ad-type option:selected').val()
			showHideSectionByAdType({ formEle: formEle, adType: currentAdType })
			// change ad type
			adTypeSelect.addEventListener("change", function() {
				// get selected ad type
				let selectedAdType = $('select#ad-type option:selected').val()
				showHideSectionByAdType({ formEle: formEle, adType: selectedAdType })
			})
		} else if (formEle.querySelector('.form-check-touch input[type=radio]')) {
			const radioEle = formEle.querySelectorAll('.form-check-touch input[type=radio]')
			// get current ad type
			const currentAdType = $("input[type='radio'][name='ad_type']:checked").val()
			showHideSectionByAdType({ formEle: formEle, adType: currentAdType })
			// loop
			for(var i = 0; i < radioEle.length; i++) {
				radioEle[i].addEventListener("change", function() {
					// get selected ad type
					const selectedAdType = $("input[type='radio'][name='ad_type']:checked").val()
					showHideSectionByAdType({ formEle: formEle, adType: selectedAdType })
				})
			}
		}
	}
}

// property type select
function selectPropertyType() {
	if (document.querySelector('.advanced-search-form')) {
		const formEle = document.querySelector('.advanced-search-form')
		if (formEle.querySelector('select#property-type')) {
			const propertyTypeSelect = formEle.querySelector('select#property-type')
			// get current property type
			const currentPropertyType = $('select#property-type option:selected').val();
			// show/hide sections
			showHideSectionByPropertyType({ formEle: formEle, propertyType: currentPropertyType })
			// change property type
			propertyTypeSelect.addEventListener("change", function() {
				// get selected property type
				const selectedPropertyType = $('select#property-type option:selected').val();
				// show/hide sections
				showHideSectionByPropertyType({ formEle: formEle, propertyType: selectedPropertyType })
			})
		}
	}
}

// filter deopdown
function searchFilterDropdown() {
	const filterEle = document.querySelectorAll('.dropdown-filter')
	for (let i = 0; i < filterEle.length; i++) {
		const filtrBtn = filterEle[i].querySelector('.btn-filter')
		filtrBtn.addEventListener("click", function() {
			var box = $(this).closest('.dropdown-filter').find('.open-filter');
			var btn = $(this).closest('.dropdown-filter').find('.btn-filter');
			if (!box.hasClass("active")) {
				box.addClass('active');
			} else 
				box.removeClass('active');
			if (!btn.hasClass("active"))
				btn.addClass('active');
			else
				btn.removeClass('active'); 
		});
		$(document).on("click", function (a) {
			if ($(a.target).closest(".dropdown-filter").length === 0) {
				$('.dropdown-filter').find('.open-filter').removeClass("active");
				$('.dropdown-filter').find('.btn-filter').removeClass("active");
			}
		});
	}
}

// load
window.addEventListener('load', function () {
	selectAdType()
	selectPropertyType()
	searchFilterDropdown()
})

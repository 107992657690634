// number format
export function numberFormat({ boolean, section, length }) {
	if (boolean) {
		section.addEventListener('input', function() {
			const reg = /^0+/gi;
			if (this.value.match(reg)) {
				this.value = this.value.replace(reg, '')
			}
			let maxLength = 10
			if (length) {
				maxLength = length
			}
			const inputValue = this.value.replace(/[^0-9]/g, '').substring(0, maxLength)
			section.value = inputValue
		})
	}
}

// decimal point format
export function decimalPointFormat({ boolean, section }) {
	if(boolean) {
		section.addEventListener('input', function() {
			const inputValue = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
			let firstVal, secondVal, inputVal
			firstVal = inputValue.split(".")[0]
			secondVal = inputValue.split(".")[1]
			if(secondVal) {
				inputVal = firstVal + '.' + secondVal.substring(0,2)
			} else {
				inputVal = inputValue
			}
			section.value = inputVal
		})
	}
}

// phone number format
export function phoneNumberFormat() {
	$('.phone').simpleMask({ 'mask': '(###) ###-##-##' });
}

// lazyload
export function lazyLoadImages() {
	$("img.lazyload").lazyload();
}
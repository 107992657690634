// import functions
import { showHideSectionByPropertyType, showHideSectionByAdType, propertySetLocation } from './showHidePropertySections';

// select ad type
function selectAdType() {
	if (document.querySelector('.property-form')) {
		const formEle = document.querySelector('.property-form')
		if (formEle.querySelector('select#ad-type')) {
			if (!document.querySelector('.property-form').querySelector('.edit-property')) {
				showHideSectionByAdType({ formEle: formEle, })
			}
			const adTypeSelect = formEle.querySelector('select#ad-type')
			// change ad type
			adTypeSelect.addEventListener("change", function() {
				// get selected ad type
				let selectedAdType = $('select#ad-type option:selected').val()
				showHideSectionByAdType({ formEle: formEle, adType: selectedAdType })
			})
		} else if (formEle.querySelector('.form-check-touch input[type=radio]')) {
			const radioEle = formEle.querySelectorAll('.form-check-touch input[type=radio]')
			// get current ad type
			const currentAdType = $("input[type='radio']:checked").val()
			showHideSectionByAdType({ formEle: formEle, adType: currentAdType })
			// loop
			for(var i = 0; i < radioEle.length; i++) {
				radioEle[i].addEventListener("change", function() {
					// get selected ad type
					const selectedAdType = $("input[type='radio']:checked").val()
					showHideSectionByAdType({ formEle: formEle, adType: selectedAdType })
				})
			}
		}
	}
}

// property type select
function selectPropertyType() {
	if(document.querySelector('.property-form')) {
		const formEle = document.querySelector('.property-form')
		if(formEle.querySelector('select#property-type')) {
			const propertyTypeSelect = formEle.querySelector('select#property-type')
			// get current property type
			const currentPropertyType = $('select#property-type option:selected').val();
			// show/hide sections
			showHideSectionByPropertyType({ formEle: formEle, propertyType: currentPropertyType })
			// change property type
			propertyTypeSelect.addEventListener("change", function() {
				// get selected property type
				const selectedPropertyType = $('select#property-type option:selected').val();
				// show/hide sections
				showHideSectionByPropertyType({ formEle: formEle, propertyType: selectedPropertyType })
			})
		}
	}
}

// property submit
function propertySubmit() {
	if (document.querySelector('#property-submit')) {
		const formEle = document.querySelector('#property-submit')
		let dataUrl
		if (formEle.classList.contains('edit-property')) {
			let propertyId = formEle.querySelector('.property-id').value
			dataUrl = "" + base_url + "/property/" + propertyId + "/update"
		} else {
			dataUrl = "" + base_url + "/property/store"
		}
		formEle.addEventListener('submit', function(e) {
			e.preventDefault();
			let formData = new FormData(this);
			$.ajax({
				data: formData,
				url: dataUrl,
				type: "POST",
				dataType: 'json',
				contentType: false,
				cache: false,
				processData: false,
				beforeSend: function() {
					$('.ajax-loader').css("visibility", "visible");
				},
				success: function(response) {
					if (response.status === true) {
						resetErrorMessages()
						if(response.type == 'success') {
							window.location.href = base_url + "/elan/info/" + response.property + "?ref=" + response.ref + "";
						}
					} else {
						resetErrorMessages()
						if(response.type == 'image') {
							const imageSection = formEle.querySelector('.property-images')
							if(imageSection.querySelector('.error')) {
								const errorEle = imageSection.querySelector('.error')
								imageSection.scrollIntoView({behavior: 'smooth'})
								errorEle.innerHTML = response.errors
								errorEle.classList.remove('d-none')
							}
						} else if(response.type == 'limit') {
							const buttonSection = formEle.querySelector('.property-button')
							if(buttonSection.querySelector('.error')) {
								const errorEle = buttonSection.querySelector('.error')
								errorEle.innerHTML = `<div class="alert alert-danger" role="alert">
								<i class="ri-error-warning-line"></i> ${response.errors}</div>`
								errorEle.classList.remove('d-none')
							}
						} else {
							$.each(response.errors, function (key, value) {
								const inputEle = formEle.querySelector("." + key)
								if(inputEle.parentNode.classList.contains('input-group')) {
									if(inputEle.parentNode.nextElementSibling.classList.contains('error')) {
										if(inputEle.classList.contains(key)) {
											inputEle.parentNode.focus()
											inputEle.parentNode.nextElementSibling.innerHTML = value[0]
											inputEle.parentNode.nextElementSibling.classList.remove('d-none')
										} else {
											inputEle.parentNode.nextElementSibling.innerHTML = ''
											inputEle.parentNode.nextElementSibling.classList.add('d-none')
										}
									}
								} else {
									if(inputEle.nextElementSibling.classList.contains('error')) {
										if(inputEle.classList.contains(key)) {
											inputEle.focus()
											inputEle.nextElementSibling.innerHTML = value[0]
											inputEle.nextElementSibling.classList.remove('d-none')
										} else {
											inputEle.nextElementSibling.innerHTML = ''
											inputEle.nextElementSibling.classList.add('d-none')
										}
									}
								}
							})
						}
					}
				},
				error: function (err) {
					alert(err)
				},
				complete: function() {
					$('.ajax-loader').css("visibility", "hidden");
				}
			});
			
		})
		// reset error messages
		function resetErrorMessages() {
			// image
			const imageSection = formEle.querySelector('.property-images')
			if(imageSection.querySelector('.error')) {
				const errorEle = imageSection.querySelector('.error')
				errorEle.innerHTML = ''
				errorEle.classList.add('d-none')
			}
			// input
			const inputList = formEle.querySelectorAll(".form-field")
			inputList.forEach(el => {
				if(el.parentNode.classList.contains('input-group')) {
					if(el.parentNode.nextElementSibling.classList.contains('error')) {
						el.parentNode.nextElementSibling.innerHTML = ''
						el.parentNode.nextElementSibling.classList.add('d-none')
					}
				} else {
					if(el.nextElementSibling.classList.contains('error')) {
						el.nextElementSibling.innerHTML = ''
						el.nextElementSibling.classList.add('d-none')
					}
				}
			})
		}
	}
}

// admin update property
function propertyUpdate() {
	if(document.querySelector('#property-submit')) {
		const formEle = document.querySelector('#property-submit')
		formEle.addEventListener('submit', function(e) {
			e.preventDefault();
			let formData = new FormData(this);
			$.ajax({
				data: formData,
				url: "" + base_url + "/admin/properties/ajax/update",
				type: "POST",
				dataType: 'json',
				contentType: false,
				cache: false,
				processData: false,
				beforeSend: function() {
					$('.ajax-loader').css("visibility", "visible");
				},
				success: function(response) {
					if (response.status === true) {
						resetErrorMessages()
						if(response.type == 'success') {
							notification(response.message, 'success')
							$("#properties-table").DataTable().ajax.reload()
							$('#viewPropertyModal').modal('hide');
						}
					} else {
						resetErrorMessages()
						if(response.type == 'image') {
							const imageSection = formEle.querySelector('.property-images')
							if(imageSection.querySelector('.error')) {
								const errorEle = imageSection.querySelector('.error')
								imageSection.scrollIntoView({behavior: 'smooth'})
								errorEle.innerHTML = response.errors
								errorEle.classList.remove('d-none')
							}
						} else if(response.type == 'image') {
							notification(response.message, 'error')
						} else {
							$.each(response.errors, function (key, value) {
								notification(value[0], 'error')
							})
						}
					}
				},
				error: function (err) {
					alert(err)
				},
				complete: function() {
					$('.ajax-loader').css("visibility", "hidden");
				}
			});
			
		})
		// reset error messages
		function resetErrorMessages() {
			// image
			const imageSection = formEle.querySelector('.property-images')
			if(imageSection.querySelector('.error')) {
				const errorEle = imageSection.querySelector('.error')
				errorEle.innerHTML = ''
				errorEle.classList.add('d-none')
			}
			// input
			const inputList = formEle.querySelectorAll(".form-field")
			inputList.forEach(el => {
				if(el.parentNode.classList.contains('input-group')) {
					if(el.parentNode.nextElementSibling.classList.contains('error')) {
						el.parentNode.nextElementSibling.innerHTML = ''
						el.parentNode.nextElementSibling.classList.add('d-none')
					}
				} else {
					if(el.nextElementSibling.classList.contains('error')) {
						el.nextElementSibling.innerHTML = ''
						el.nextElementSibling.classList.add('d-none')
					}
				}
			})
		}
	}
}

function property() {
	selectAdType()
	selectPropertyType()
	propertySetLocation()
	propertySubmit()
}

// load
window.addEventListener('load', function () {
	property()
})

// export
export function addProperty() {
	property()
}

// export admin
export function updateProperty() {
	selectAdType()
	selectPropertyType()
	propertySetLocation()
	propertyUpdate()
}